<template>
  <en-drawer title="项目返工" :model-value="modelValue" @close="$emit('update:model-value', false)">
    <en-form :model="form.data" :rules="form.rules" :ref="setRef('form')">
      <en-form-item label="项目名称">{{ data?.name }}</en-form-item>
      <en-form-item label="施工班组">{{ data?.workingTeam?.name }}</en-form-item>
      <en-form-item label="施工人员">{{ data?.assignees?.map((item) => (item.assignee ? item.assignee.name : item.name)).join(',') }}</en-form-item>
      <en-form-item label="终检人" prop="finalInspector.id">
        <select-maintain
          v-model="form.data.finalInspector"
          :ajax="{ action: 'GET /enocloud/common/finalinspector' }"
          :props="{
            label: 'inspector.name',
            value: 'inspector',
            disabled: (option: EnocloudCommonDefinitions['FinalInspectorDto']) => option.inspector?.status?.code === 'Y'
          }"
          value-key="id"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="检验时间" prop="finalInspectorDatetime">
        <en-date-picker v-model="form.data.finalInspectorDatetime" type="datetime" value-format="YYYY-MM-DDThh:mm:ss" class="w-full"></en-date-picker>
      </en-form-item>
      <en-form-item label="返工原因">
        <en-input type="textarea" v-model="form.data.reason"></en-input>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <en-button type="primary" @click="footer.confirm.click">确定</en-button>
    </template>
  </en-drawer>
</template>

<script lang="ts">
const formDataInit = () => {
  return {
    finalInspector: { name: '' } as EnocloudServiceDefinitions['UserDto'],
    finalInspectorDatetime: '',
    reason: ''
  }
}

export default factory({
  props: {
    modelValue: Boolean,
    data: Object as PropType<EnocloudServiceDefinitions['ServiceMaintenanceDto'] | null>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: (value: Definitions['ServiceMaintenanceInternalReworkDto']) => typeof value === 'object'
  },

  watch: {
    modelValue: {
      handler(value) {
        if (!value) this.form.data = formDataInit()
      }
    }
  },

  config: {
    children: {
      form: {
        data: formDataInit(),
        rules: {
          'finalInspector.id': [{ required: true, message: '请选择终检人' }],
          finalInspectorDatetime: [{ required: true, message: '请选择检验时间' }]
        }
      },
      footer: {
        children: {
          cancel: {
            click() {
              this.emit('update:model-value', false)
            }
          },
          confirm: {
            async click() {
              await this.refs.form.validate()
              this.emit('confirm', this.form.data)
              this.emit('update:model-value', false)
            }
          }
        }
      }
    }
  }
})
</script>
